import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation, useQuery } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Copy,
  Date as DateComponent,
  OptionsMenu,
  Value,
  renderOptionalValue,
} from '@/components';
import { EditIcon, RefreshIcon } from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { useTranslate } from '@/contexts';
import { PlayerUpdateAffiliateInfoForm } from '@/forms';
import { useCan } from '@/utils/access';
import formatCountryCode from '@/utils/formatter/formatCountryCode';
import formatDate from '@/utils/formatter/formatDate';
import {
  PlayerActivityInfoBox,
  PlayerActivityInfoBoxVariables,
} from './__generated__/PlayerActivityInfoBox';
import {
  RemoveAffiliateParametersMutation,
  RemoveAffiliateParametersMutationVariables,
} from './__generated__/RemoveAffiliateParametersMutation';
import {
  ResetGlobalInvalidLoginAttemptsLeftMutation,
  ResetGlobalInvalidLoginAttemptsLeftMutationVariables,
} from './__generated__/ResetGlobalInvalidLoginAttemptsLeftMutation';
import { SanityPlayerActivityInfoBlockFragment } from './__generated__/SanityPlayerActivityInfoBlockFragment';

export const Fragment = graphql`
  fragment SanityPlayerActivityInfoBlockFragment on SanityPlayerActivityInfoBlock {
    title {
      ...LocaleString
    }
    edit {
      ...LocaleString
    }
    resetLoginAttempts {
      ...LocaleString
    }
    registered {
      ...LocaleString
    }
    registrationIp {
      ...LocaleString
    }
    lastLogin {
      ...LocaleString
    }
    lastLoginIp {
      ...LocaleString
    }
    loginAttemptsLeft {
      ...LocaleString
    }
    reachedAt {
      ...LocaleString
    }
    firstDeposit {
      ...LocaleString
    }
    lastDeposit {
      ...LocaleString
    }
    lastBonusAdjustment {
      ...LocaleString
    }
    affiliateId {
      ...LocaleString
    }
    affiliateToken {
      ...LocaleString
    }
  }
`;

const QUERY = gql`
  query PlayerActivityInfoBox($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      depositInfoV2 {
        first
        last
      }
      loginInfo {
        lastLogin {
          ip
          loggedAt
          countryCode
        }
        registration {
          ip
          loggedAt
          countryCode
        }
      }
      lastBonusAdjustment: transactionsV2(
        first: 1
        adjustmentCategory: Bonus
        orderBy: processedAt
        desc: true
      ) {
        edges {
          node {
            rawTransactionId
            processedAt
          }
        }
      }
      authenticationMethod {
        invalidGlobalLoginAttemptsAllowed
        maxGlobalLoginAttemptsReachedAt
      }
      affiliateInfo {
        affiliateToken
        affiliateId
      }
    }
  }
`;

const removeAffiliateParametersMutation = gql`
  mutation RemoveAffiliateParametersMutation($playerId: ID!) {
    removeAffiliateParameters(playerId: $playerId) {
      id
      affiliateInfo {
        affiliateId
        affiliateToken
      }
    }
  }
`;

const resetGlobalInvalidLoginAttemptsLeft = gql`
  mutation ResetGlobalInvalidLoginAttemptsLeftMutation($playerId: ID!) {
    resetGlobalInvalidLoginAttemptsLeft(playerId: $playerId) {
      id
      authenticationMethod {
        invalidGlobalLoginAttemptsAllowed
        maxGlobalLoginAttemptsReachedAt
      }
    }
  }
`;

const PlayerActivityInfoBlock: FC<{
  block: SanityPlayerActivityInfoBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const params = useParams();
  const feedbackMessages = useFeedbackMessages();
  const canManageAffiliates = useCan('MANAGE_AFFILIATES');

  const [{ data, fetching }, refresh] = useQuery<
    PlayerActivityInfoBox,
    PlayerActivityInfoBoxVariables
  >({
    query: QUERY,
    variables: {
      playerId: params.playerId,
    },
  });

  const [, removeAffiliateInfo] = useMutation<
    RemoveAffiliateParametersMutation,
    RemoveAffiliateParametersMutationVariables
  >(removeAffiliateParametersMutation);

  const [, resetInvalidLogins] = useMutation<
    ResetGlobalInvalidLoginAttemptsLeftMutation,
    ResetGlobalInvalidLoginAttemptsLeftMutationVariables
  >(resetGlobalInvalidLoginAttemptsLeft);

  const hasAffiliateInfo =
    !!data?.player.affiliateInfo?.affiliateId ||
    !!data?.player.affiliateInfo?.affiliateToken;

  const affiliateOptionsMenu = (
    <OptionsMenu
      items={[
        {
          content: t(block.edit),
          icon: <EditIcon />,
          modalContent: data?.player.id != null && (
            <PlayerUpdateAffiliateInfoForm playerId={data.player.id} />
          ),
        },
        {
          content: 'Remove all affiliate info',
          icon: <TrashIcon />,
          shouldConfirm: true,
          isHidden: !hasAffiliateInfo,
          onClick: () => {
            if (data?.player.id) {
              removeAffiliateInfo({
                playerId: data?.player.id,
              }).then((res) => {
                if (res.error?.message) {
                  feedback.error(res.error.message);
                } else {
                  feedback.success(t(feedbackMessages.success));
                }
              });
            }
          },
        },
      ]}
    />
  );

  const loginAttemptOptionsMenu = (
    <OptionsMenu
      items={[
        {
          content: t(block.resetLoginAttempts),
          icon: <RefreshIcon />,
          shouldConfirm: true,
          onClick: () => {
            if (data?.player.id) {
              resetInvalidLogins({
                playerId: data?.player.id,
              }).then((res) => {
                if (res.error?.message) {
                  feedback.error(res.error.message);
                } else {
                  feedback.success(t(feedbackMessages.success));
                }
              });
            }
          },
        },
      ]}
    />
  );

  const lastBonusAdjustment = data?.player.lastBonusAdjustment.edges?.[0]?.node;

  return (
    <Card
      size="lg"
      title={
        <>
          {t(block.title)}{' '}
          <Copy fetching={fetching} value={data?.player.uuid} />
        </>
      }
      options={
        <CardOptions>
          <CardOptionsButton
            className="flex"
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          <Value fetching={fetching} title={t(block.registered)}>
            {formatCountryCode(
              data?.player?.loginInfo?.registration.countryCode,
            )}{' '}
            <DateComponent
              copyable
              date={data?.player?.loginInfo?.registration?.loggedAt}
            />
          </Value>
          <Value fetching={fetching} title={t(block.registrationIp)}>
            {formatCountryCode(
              data?.player?.loginInfo?.registration.countryCode,
            )}{' '}
            <Copy value={data?.player?.loginInfo?.registration?.ip} />
          </Value>
          <Value fetching={fetching} title={t(block.lastLogin)}>
            {formatCountryCode(data?.player?.loginInfo?.lastLogin.countryCode)}{' '}
            <DateComponent
              copyable
              date={data?.player?.loginInfo?.lastLogin?.loggedAt}
            />
          </Value>{' '}
          <Value fetching={fetching} title={t(block.lastLoginIp)}>
            {formatCountryCode(data?.player?.loginInfo?.lastLogin.countryCode)}{' '}
            <Copy value={data?.player?.loginInfo?.lastLogin?.ip} />
          </Value>
          <Value fetching={fetching} title={t(block.loginAttemptsLeft)}>
            {data?.player?.authenticationMethod
              ?.maxGlobalLoginAttemptsReachedAt ? (
              <>
                <Copy
                  value={`0, ${t(block.reachedAt, {
                    date: formatDate(
                      new Date(
                        data?.player?.authenticationMethod?.maxGlobalLoginAttemptsReachedAt,
                      ),
                    ),
                  })}`}
                />
                {loginAttemptOptionsMenu}
              </>
            ) : (
              <Copy
                value={renderOptionalValue(
                  data?.player?.authenticationMethod
                    ?.invalidGlobalLoginAttemptsAllowed,
                )}
              />
            )}
          </Value>
          <Value fetching={fetching} title={t(block.firstDeposit)}>
            <DateComponent copyable date={data?.player?.depositInfoV2?.first} />
          </Value>
          <Value fetching={fetching} title={t(block.lastDeposit)}>
            <DateComponent
              copyable
              date={data?.player?.depositInfoV2?.last}
              presentation="timeAgo"
            />
          </Value>
          <Value fetching={fetching} title={t(block.lastBonusAdjustment)}>
            <DateComponent copyable date={lastBonusAdjustment?.processedAt} />
          </Value>
          <Value
            fetching={fetching}
            title={t(block.affiliateId)}
            value={data?.player.affiliateInfo?.affiliateId}
            suffix={canManageAffiliates && affiliateOptionsMenu}
          >
            {data?.player.affiliateInfo?.affiliateId ? (
              <a
                href={`https://admin.79affiliates.com/search_results.php?search=true&search_user_id=${data?.player.affiliateInfo?.affiliateId}`}
                target="blank"
                rel="noopener noreferrer"
              >
                {data?.player.affiliateInfo?.affiliateId}
              </a>
            ) : (
              '-'
            )}
          </Value>
          <Value
            fetching={fetching}
            title={t(block.affiliateToken)}
            value={data?.player.affiliateInfo?.affiliateToken}
            suffix={canManageAffiliates && affiliateOptionsMenu}
          >
            {renderOptionalValue(data?.player.affiliateInfo?.affiliateToken)}
          </Value>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlayerActivityInfoBlock;
